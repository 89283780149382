<template>
  <header>
    <!-- header content begin -->
    <div class="uk-section uk-padding-remove-vertical">
      <nav
        class="uk-navbar-container uk-navbar-dark uk-sticky"
        data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top;"
        style=""
      >
        <div class="uk-container uk-navbar" data-uk-navbar="">
          <div class="uk-navbar-left">
            <div class="uk-navbar-item">
              <!-- logo begin -->
              <router-link class="uk-logo" :to="{ name: 'trader.home' }">
                <img
                  src="/logo.png"
                  data-src="/logo.png"
                  alt="logo"
                  width="146"
                  height="39"
                  data-uk-img=""
                />
              </router-link>
              <!-- logo end -->
              <!-- navigation begin -->
              <ul class="uk-navbar-nav uk-visible@m">
                <li>
                  <router-link :to="{ name: 'trader.home' }">
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'trader.discussions.index' }">
                    Discussion
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'trader.conference.index' }">
                    Conference
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'trader.correlation.index' }">
                    Correlation
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'trader.academy.index' }">
                    Academy
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'trader.brokers' }">
                    Resources
                    <i class="fas fa-chevron-down uk-margin-small-left"></i>
                  </router-link>
                  <div
                    class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left"
                    style="left: 417.422px; top: 81px"
                  >
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <a
                          href="https://myforexglobal.com/blog"
                          target="_blank"
                        >
                          Blog
                        </a>
                      </li>
                      <li>
                        <router-link
                          :to="{ name: 'trader.elite-videos.index' }"
                        >
                          Elite Videos
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          :to="{ name: 'trader.post-analysis.index' }"
                        >
                          Post Analysis
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ name: 'trader.brokers' }">
                          Top Brokers
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ name: 'trader.get-funded' }">
                          Get Funded
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ name: 'trader.users.index' }">
                          Users
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <!-- navigation end -->
              <div class="uk-navbar-item in-mobile-nav uk-hidden@m">
                <a
                  class="uk-button"
                  href="#modal-full"
                  data-uk-toggle=""
                  aria-expanded="false"
                  ><i class="fas fa-bars"></i
                ></a>
                <div
                  id="modal-full"
                  class="uk-modal-full uk-modal"
                  data-uk-modal=""
                >
                  <div
                    class="
                      uk-modal-dialog uk-flex uk-flex-center uk-flex-middle
                    "
                    data-uk-height-viewport=""
                  >
                    <a class="uk-modal-close-full uk-button" id="uk-modal-close"
                      ><i class="fas fa-times"></i
                    ></a>
                    <div class="uk-width-large uk-padding-large">
                      <ul
                        class="uk-nav-default uk-nav-parent-icon uk-nav"
                        data-uk-nav=""
                      >
                        <li>
                          <router-link :to="{ name: 'trader.home' }">
                            Home
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name: 'trader.discussions.index' }"
                          >
                            Discussion
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name: 'trader.conference.index' }"
                          >
                            Conference
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name: 'trader.correlation.index' }"
                          >
                            Correlation
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'trader.academy.index' }">
                            Academy
                          </router-link>
                        </li>
                        <li class="uk-parent">
                          <a href="#" aria-expanded="false">Resources</a>
                          <ul class="uk-nav-sub" hidden="">
                            <li>
                              <a
                                href="https://myforexglobal.com/blog"
                                target="_blank"
                              >
                                Blog
                              </a>
                            </li>
                            <li>
                              <router-link
                                :to="{ name: 'trader.elite-videos.index' }"
                              >
                                Elite Videos
                              </router-link>
                            </li>
                            <li>
                              <router-link
                                :to="{ name: 'trader.post-analysis.index' }"
                              >
                                Post Analysis
                              </router-link>
                            </li>
                            <li>
                              <router-link :to="{ name: 'trader.brokers' }">
                                Top Brokers
                              </router-link>
                            </li>
                            <li>
                              <router-link :to="{ name: 'trader.get-funded' }">
                                Get Funded
                              </router-link>
                            </li>
                            <li>
                              <router-link :to="{ name: 'trader.users.index' }">
                                Users
                              </router-link>
                            </li>
                          </ul>
                        </li>
                        <li class="uk-parent">
                          <a href="#" aria-expanded="false">My Account</a>
                          <ul class="uk-nav-sub" hidden="">
                            <li>
                              <router-link :to="{ name: 'trader.my-account' }"
                                >My Account</router-link
                              >
                            </li>
                            <li>
                              <router-link :to="{ name: 'trader.billing' }"
                                >Billing</router-link
                              >
                            </li>
                            <li>
                              <router-link :to="{ name: 'trader.messages' }"
                                >Messages</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#" @click.prevent="logout">Logout</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav uk-visible@m">
              <li v-if="$route.name == 'trader.academy.index'">
                <a href="#" aria-expanded="false">
                  <span
                    class="fa fa-trophy"
                    style="font-size: 20px; margin-right: 10px"
                  ></span>
                  Your Progress
                </a>
                <div
                  class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left"
                  style="left: 417.406px; top: 92px"
                >
                  <div
                    id="completed-weeks-header"
                    style="display: inline; font-weight: bold"
                  ></div>
                </div>
              </li>
              <li>
                <router-link
                  :to="{ name: 'trader.messages' }"
                  class="uk-button uk-button-text"
                >
                  <i
                    class="fas fa-envelope uk-margin-small-right"
                    style="font-size: 20px"
                  ></i>
                  <span v-if="user.unread_messages_count > 0"
                    >({{ user.unread_messages_count }})</span
                  >
                  <span class="uk-hidden@m">Messages</span>
                </router-link>
              </li>
              <li>
                <a href="#" aria-expanded="false"
                  >{{ first_name }}<i class="fas fa-chevron-down"></i
                ></a>
                <div
                  class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left"
                  style="left: 417.406px; top: 92px"
                >
                  <ul class="uk-nav uk-navbar-dropdown-nav">
                    <li>
                      <router-link :to="{ name: 'trader.my-account' }"
                        >My Account</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'trader.billing' }"
                        >Billing</router-link
                      >
                    </li>
                    <li><a href="#" @click.prevent="logout">Logout</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        class="uk-sticky-placeholder"
        style="height: 92px; margin: 0px"
        hidden=""
      ></div>
    </div>
    <!-- header content end -->
  </header>
</template>

<script>
export default {
  mounted() {
    this.$echo.private(`messages.${this.user.id}`).listen("MessageSent", () => {
      this.user.unread_messages_count++;
    });
  },

  methods: {
    logout() {
      this.$loader.start();
      this.$axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },
  },

  computed: {
    first_name() {
      return this.$store.getters.getAuthUser.name.split(" ")[0];
    },

    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
